<template>
  <v-app id="inspire">
    <v-navigation-drawer
      v-model="drawer"
      :clipped="$vuetify.breakpoint.lgAndUp"
      v-if="esAdministrador || esSupervisor || esVendedor || esFacturador || esCrifasa || esBodega || esBoss || esContador || esMarketing || KingSupervisor || esReceptor || esTaller"
      :src="require('../assets/FondoNoah2.jpg')"
      color="primary"
      fixed
      dark
      temporary=""
      app
      overlay-color="black"
      overlay-opacity=".8"
    >
      <template v-slot:prepend>
        <v-list-item class="px-2" two-line>
          <v-list-item-avatar>
            <img :src="require('../assets/Favicon.png')">
          </v-list-item-avatar>

          <v-list-item-content >
            <v-list-item-title>{{userName}}</v-list-item-title>
            <v-list-item-subtitle style="font-weight:bold;">{{Localidad}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
      <v-list style="font-weight:bold;" v-if="Pais == 'ESP'" dense>
        <template  v-for="item in items">
          <v-row
            v-if="item.items"
            :key="item.heading"
            align="center"
          >
            <v-col cols="6">
              <v-subheader v-if="item.heading">
                {{ item.heading }}
              </v-subheader>
            </v-col>
            <v-col
              cols="6"
              class="text-center"
            >
              <a
                href="#!"
                class="body-2 white--text"
              >EDIT</a>
            </v-col>
          </v-row>
          <v-list-group
            v-else-if="item.children"
            :key="item.text"
            dark
            
            v-model="item.model"
            :prepend-icon="item.model ? item.icon : item['icon-alt']"
            append-icon=""
            class="white--text"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="(child, i) in item.children"
              :key="i"
              dark
              router :to="child.route"
              link
            >
              <v-list-item-action v-if="child.icon">
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ child.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item 
            router :to="item.route"
            :key="item.text"
            v-if="esAdministrador || esSupervisor"
            link
          >
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          
          
        </template>
		
		<v-list-item 
			to='/inventarioactual'
			v-if="esTaller || esVendedor"
		>
            <v-list-item-action>
				<v-icon>mdi-bag-carry-on</v-icon>
            </v-list-item-action>
            <v-list-item-content>
				<v-list-item-title>
					Inventario
				</v-list-item-title>
            </v-list-item-content>
		</v-list-item>
		<v-list-item 
			to='/ordenestaller'
			v-if="esTaller"
		>
			<v-list-item-action>
				<v-icon>mdi-cash-register</v-icon>
			</v-list-item-action>
			<v-list-item-content>
				<v-list-item-title>
					Crear Orden
				</v-list-item-title>
			</v-list-item-content>
		</v-list-item>
		<v-list-item 
			v-if="esCrifasa"
			to='/proformas'
		>
			<v-list-item-action>
			<v-icon>mdi-clipboard-file</v-icon>
			</v-list-item-action>
			<v-list-item-content>
			<v-list-item-title>
				Ordenes
			</v-list-item-title>
			</v-list-item-content>
		</v-list-item>
		<div v-if="!esCrifasa">
				<v-list-item 
					to='/panorama'
					v-if="esBodega || esBoss || esAdministrador || esSupervisor || esTaller"
					class="error"
				>
					<v-list-item-action>
					<v-icon>mdi-shield-star</v-icon>
					</v-list-item-action>
					<v-list-item-content>
					<v-list-item-title>
						Panorama general
					</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item 
					to='/aprobaciones'
					v-if="esSupervisor || esVendedor"
				>
					<v-list-item-action>
					<v-icon>mdi-clipboard-file</v-icon>
					</v-list-item-action>
					<v-list-item-content>
					<v-list-item-title>
						Aprobaciones
					</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item 
					to='/'
					v-if="esBoss"
				>
					<v-list-item-action>
					<v-icon>mdi-clipboard-file</v-icon>
					</v-list-item-action>
					<v-list-item-content>
					<v-list-item-title>
						Dashboard
					</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item 
					to='/despacho'
					v-if="esBoss"
				>
					<v-list-item-action>
					<v-icon>mdi-speedometer</v-icon>
					</v-list-item-action>
					<v-list-item-content>
					<v-list-item-title>
						Comandas
					</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item 
					to='/pos'
					v-if="esVendedor"
				>
					<v-list-item-action>
					<v-icon>mdi-cash-register</v-icon>
					</v-list-item-action>
					<v-list-item-content>
					<v-list-item-title>
						POS
					</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item 
					to='/facturar/0/0'
					v-if="!esMarketing"
				>
					<v-list-item-action>
					<v-icon>mdi-cash-register</v-icon>
					</v-list-item-action>
					<v-list-item-content>
					<v-list-item-title>
						Facturador
					</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item 
					to='/productosweb'
					v-if="esBodega || esBoss || esMarketing || esTaller"
				>
					<v-list-item-action>
					<v-icon>mdi-ipod</v-icon>
					</v-list-item-action>
					<v-list-item-content>
					<v-list-item-title>
						Productos
					</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item 
					to='/compras'
					v-if="esBodega || esBoss || esMarketing || esBodega || esTaller"
				>
					<v-list-item-action>
					<v-icon>mdi-shopping</v-icon>
					</v-list-item-action>
					<v-list-item-content>
					<v-list-item-title>
						Egresos
					</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item 
					to='/ajusteinventario'
					v-if="esBoss"
				>
					<v-list-item-action>
					<v-icon>mdi-bank-transfer-in</v-icon>
					</v-list-item-action>
					<v-list-item-content>
					<v-list-item-title>
						Ajuste Inventario
					</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item 
					to='/dashboard'
					v-if="esBodega|| esBoss"
				>
					<v-list-item-action>
					<v-icon>mdi-bank-transfer</v-icon>
					</v-list-item-action>
					<v-list-item-content>
					<v-list-item-title>
						Traslados 
					</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item 
					to='/inventarioactual'
					v-if="esBodega || esBoss || esMarketing || KingSupervisor"
				>
					<v-list-item-action>
					<v-icon>mdi-bag-carry-on</v-icon>
					</v-list-item-action>
					<v-list-item-content>
					<v-list-item-title>
						Inventario Actual
					</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item 
					to='/repxproducto'
					v-if="esBodega || esBoss || esMarketing || KingSupervisor"
				>
					<v-list-item-action>
					<v-icon>mdi-smoking-pipe</v-icon>
					</v-list-item-action>
					<v-list-item-content>
					<v-list-item-title>
						Ventas Por Producto
					</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
        <v-list-item 
					to='/trazabilidad'
					v-if="esBodega || esBoss || esMarketing || KingSupervisor"
				>
					<v-list-item-action>
					<v-icon>mdi-radar</v-icon>
					</v-list-item-action>
					<v-list-item-content>
					<v-list-item-title>
						Trasabilidad items
					</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item 
					to='/reporteminimos'
					v-if="esBodega || esBoss"
				>
					<v-list-item-action>
					<v-icon>mdi-battery-low</v-icon>
					</v-list-item-action>
					<v-list-item-content>
					<v-list-item-title>
						Reporte Minimos
					</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item 
					to='/compuestos'
					v-if="esBodega || esBoss"
				>
					<v-list-item-action>
					<v-icon>mdi-hockey-puck</v-icon>
					</v-list-item-action>
					<v-list-item-content>
					<v-list-item-title>
						Compuestos
					</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				
				<v-list-item 
					to='/proformas'
					v-if="!esMarketing"
				>
					<v-list-item-action>
					<v-icon>mdi-clipboard-file</v-icon>
					</v-list-item-action>
					<v-list-item-content>
					<v-list-item-title>
						Proformas
					</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item 
					to='/usuariocliente'
					v-if="esTaller"
				>
					<v-list-item-action>
					<v-icon>mdi-account-group-outline</v-icon>
					</v-list-item-action>
					<v-list-item-content>
					<v-list-item-title>
						Control Usuarios
					</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item 
					to='/usuarios'
					v-if="esAdministrador || esBoss"
				>
					<v-list-item-action>
					<v-icon>mdi-account-group-outline</v-icon>
					</v-list-item-action>
					<v-list-item-content>
					<v-list-item-title>
						Control Usuarios
					</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item 
					to='/comandasadmin'
					v-if="esAdministrador || esBoss || esCenter"
				>
					<v-list-item-action>
					<v-icon>mdi-clipboard-file</v-icon>
					</v-list-item-action>
					<v-list-item-content>
					<v-list-item-title>
						Master Control
					</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item 
					to='/localidades'
					v-if="esAdministrador"
				>
					<v-list-item-action>
					<v-icon>mdi-bitcoin</v-icon>
					</v-list-item-action>
					<v-list-item-content>
					<v-list-item-title>
						Recargas
					</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-group v-if="esVendedor || esAdministrador || esSupervisor">
					<v-list-item slot="activator">
						<v-list-item-action>
						<v-icon>mdi-silverware-fork-knife</v-icon>
						</v-list-item-action>
						<v-list-item-content>
						<v-list-item-title>
							POS Funciones
						</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item :to="{ name: 'productosweb'}">
						<v-list-item-content>
						<v-list-item-title>
							Productos
						</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item :to="{ name: 'cocina'}">
						<v-list-item-content>
						<v-list-item-title>
							Comandas Cocina
						</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item :to="{ name: 'bar'}">
						<v-list-item-content>
						<v-list-item-title>
							Comandas Bar
						</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item :to="{ name: 'despacho'}">
						<v-list-item-content>
						<v-list-item-title>
							Comandas Despacho
						</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item :to="{ name: 'pos'}">
						<v-list-item-content>
						<v-list-item-title>
							Punto de Venta
						</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item :to="{ name: 'botones'}">
						<v-list-item-content>
						<v-list-item-title>
							Botones
						</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item :to="{ name: 'compuestos'}">
						<v-list-item-content>
						<v-list-item-title>
							Productos Compuestos
						</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item :to="{ name: 'recetas'}">
						<v-list-item-content>
						<v-list-item-title>
							Recetas
						</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list-group>
		</div>
           <template v-if="esAdministrador || esSupervisor || esBoss || esTaller">
            <v-list-group color="secondary" >
              <v-list-item slot="activator">
                <v-list-item-action>
                <v-icon>mdi-chart-box</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    Reportes
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item :to="{ name: 'repventas'}">
                <v-list-item-content>
                  <v-list-item-title>
                    Reporte Ingresos
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
			  <v-list-item :to="{ name: 'reportecrudo'}">
                <v-list-item-content>
                  <v-list-item-title>
                    Ventas Detalladas
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
			  <v-list-item :to="{ name: 'trazabilidad'}">
                <v-list-item-content>
                  <v-list-item-title>
                    Trazabilidad Productos
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
			  <v-list-item :to="{ name: 'comprasproveedor'}">
                <v-list-item-content>
                  <v-list-item-title>
                    Compras por proveedor
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item :to="{ name: 'repxproducto'}">
                <v-list-item-content>
                  <v-list-item-title>
                    Ventas por producto
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
				<v-list-item :to="{ name: 'ordenexpress'}">
                <v-list-item-content>
					<v-list-item-title>
						Ordenes Call Center
					</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			  <v-list-item :to="{ name: 'repxvendedor'}">
                <v-list-item-content>
                  <v-list-item-title>
                    Ventas por Vendedor
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
			  <v-list-item :to="{ name: 'reporteminimos'}">
                <v-list-item-content>
                  <v-list-item-title>
                    Reporte Minimos
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
			  <v-list-item :to="{ name: 'productosestancados'}">
                <v-list-item-content>
                  <v-list-item-title>
                    Productos Estancados 
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
			  <v-list-item :to="{ name: 'productosdetallados'}">
                <v-list-item-content>
                  <v-list-item-title>
                    Ventas por producto detalle
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item :to="{ name: 'repcompras'}">
                <v-list-item-content>
                  <v-list-item-title>
                    Reporte Egresos
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item :to="{ name: 'creditospagados'}">
                <v-list-item-content>
                  <v-list-item-title>
                    Créditos Pagados
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item :to="{ name: 'inventarioactual'}">
                <v-list-item-content>
                  <v-list-item-title>
                    Inventario Actual
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
			  <v-list-item :to="{ name: 'movimientoproductos'}">
                <v-list-item-content>
                  <v-list-item-title>
                    Movimiento Productos
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
			  <v-list-item :to="{ name: 'reportetraslados'}">
                <v-list-item-content>
                  <v-list-item-title>
                    	Reporte Traslados
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
          </v-list-group>
        </template>
		<template v-if="esAdministrador || esSupervisor || esContador || esBodega || esTaller" >
            <v-list-group color="secondary" >
              <v-list-item slot="activator">
                <v-list-item-action>
                <v-icon>mdi-finance</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    Contabilidad
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item :to="{ name: 'estadobancario'}">
                <v-list-item-content>
                  <v-list-item-title>
                    Estados Bancarios
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
			  <v-list-item :to="{ name: 'cuentasporcobrar'}">
                <v-list-item-content>
                  <v-list-item-title>
                    Cuentas por Cobrar
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
			  <v-list-item :to="{ name: 'cuentasporpagar'}">
                <v-list-item-content>
                  <v-list-item-title>
                    Cuentas por Pagar
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
			  <v-list-item :to="{ name: 'repventas'}">
                <v-list-item-content>
                  <v-list-item-title>
                    Ingresos
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
			  <v-list-item :to="{ name: 'repcompras'}">
                <v-list-item-content>
                  <v-list-item-title>
                    Egresos
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
			  <v-list-item :to="{ name: 'ventasxero'}">
                <v-list-item-content>
                  <v-list-item-title>
                    Ventas APP Xero
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
          </v-list-group>
        </template>
		<template v-if="esAdministrador || esSupervisor || esBoss || esMarketing" >
            <v-list-group color="secondary" >
              <v-list-item slot="activator">
                <v-list-item-action>
                <v-icon>mdi-storefront</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    eCommerce
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item :to="{ name: 'compuestos'}">
                <v-list-item-content>
                  <v-list-item-title>
                    Compuestos
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
			  <v-list-item :to="{ name: 'productosweb'}">
                <v-list-item-content>
                  <v-list-item-title>
                    Productos Tienda en Linea
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
			  <v-list-item v-if="!esMarketing" :to="{ name: 'feedback'}">
                <v-list-item-content>
                  <v-list-item-title>
                    FeedBacks
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
          </v-list-group>
        </template>
        <template v-if="esAdministrador || esSupervisor || esBoss || esTaller">
            <v-list-group color="secondary" >
              <v-list-item slot="activator">
                <v-list-item-action>
                <v-icon>mdi-tools</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    Herramientas
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
			  <v-list-item v-if="esAdministrador" :to="{ name: 'afiliacion'}">
                <v-list-item-content>
                  <v-list-item-title>
                    Crear Localidad
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
			  <v-list-item v-if="!esBoss" :to="{ name: 'inventarioasignado'}">
                <v-list-item-content>
                  <v-list-item-title>
                    Control de Activos
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
			  <v-list-item v-if="!esBoss" :to="{ name: 'trazabilidadinv'}">
                <v-list-item-content>
                  <v-list-item-title>
                    Trazabilidad Lotes
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
			  <v-list-item :to="{ name: 'tarjetaderegalo'}">
                <v-list-item-content>
                  <v-list-item-title>
                    Tarjetas de Regalo
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
			  <v-list-item :to="{ name: 'promociones'}">
                <v-list-item-content>
                  <v-list-item-title>
                    Promociones
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
			  <v-list-item v-if="!esBoss" :to="{ name: 'odt'}">
                <v-list-item-content>
                  <v-list-item-title>
                    Ordenes de Trabajo
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="!esBoss" :to="{ name: 'reparaciones'}">
                <v-list-item-content>
                  <v-list-item-title>
                    Reparaciones
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
			  <v-list-item  v-if="esAdministrador" :to="{ name: 'licencias'}">
                <v-list-item-content>
                  <v-list-item-title>
                    Licencias
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item :to="{ name: 'rol'}">
                <v-list-item-content>
                  <v-list-item-title>
                    Roles
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="!esBoss" :to="{ name: 'tutoriales'}">
                <v-list-item-content>
                  <v-list-item-title>
                    Videos Tutoriales
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="!esBoss" :to="{ name: 'seriales'}">
                <v-list-item-content>
                  <v-list-item-title>
                    Códigos Seriales
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item :to="{ name: 'panelcontrol'}">
                <v-list-item-content>
                  <v-list-item-title>
                    Panel de control
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
          </v-list-group>
        </template>
      </v-list>
	  <v-list v-if="Pais == 'ING'" dense>
        <template  v-for="item in items2">
          <v-row
            v-if="item.items"
            :key="item.heading"
            align="center"
          >
            <v-col cols="6">
              <v-subheader v-if="item.heading">
                {{ item.heading }}
              </v-subheader>
            </v-col>
            <v-col
              cols="6"
              class="text-center"
            >
              <a
                href="#!"
                class="body-2 white--text"
              >EDIT</a>
            </v-col>
          </v-row>
          <v-list-group
            v-else-if="item.children"
            :key="item.text"
            dark
            
            v-model="item.model"
            :prepend-icon="item.model ? item.icon : item['icon-alt']"
            append-icon=""
            class="white--text"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="(child, i) in item.children"
              :key="i"
              dark
              router :to="child.route"
              link
            >
              <v-list-item-action v-if="child.icon">
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ child.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item 
            router :to="item.route"
            :key="item.text"
            v-if="esAdministrador || esSupervisor "
            link
          >
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <v-list-item 
            to='/facturar/0/0'
          >
            <v-list-item-action>
              <v-icon>mdi-cash-register</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Bill
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          
          <v-list-item 
            to='/proformas'
          >
            <v-list-item-action>
              <v-icon>mdi-clipboard-file</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Proposal
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item 
            to='/usuarios'
            v-if="esAdministrador"
          >
            <v-list-item-action>
              <v-icon>mdi-account-group-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Admin Users
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
		  <v-list-group >
              <v-list-item slot="activator">
                <v-list-item-action>
                <v-icon>mdi-silverware-fork-knife</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    POS management
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item :to="{ name: 'cocina'}">
                <v-list-item-content>
                  <v-list-item-title>
                    Kitchen Orders
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
			  <v-list-item :to="{ name: 'despacho'}">
                <v-list-item-content>
                  <v-list-item-title>
                    Dispatch Orders
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item :to="{ name: 'pos'}">
                <v-list-item-content>
                  <v-list-item-title>
                    POS
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
			  <v-list-item :to="{ name: 'botones'}">
                <v-list-item-content>
                  <v-list-item-title>
                    Bottoms Management
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
          </v-list-group>
           <template v-if="esAdministrador || esSupervisor">
            <v-list-group color="secondary" >
              <v-list-item slot="activator">
                <v-list-item-action>
                <v-icon>mdi-chart-box</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    Reports
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item :to="{ name: 'repventas'}">
                <v-list-item-content>
                  <v-list-item-title>
                    General Sales Report
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item :to="{ name: 'repxproducto'}">
                <v-list-item-content>
                  <v-list-item-title>
                    Sales Report by product
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
			  <v-list-item :to="{ name: 'repxvendedor'}">
                <v-list-item-content>
                  <v-list-item-title>
                    Sales Report by Seller
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item :to="{ name: 'repcompras'}">
                <v-list-item-content>
                  <v-list-item-title>
                    Purchase Report
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item :to="{ name: 'cuentasporcobrar'}">
                <v-list-item-content>
                  <v-list-item-title>
                    Accounts receivable
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
			  <v-list-item :to="{ name: 'cuentasporpagar'}">
                <v-list-item-content>
                  <v-list-item-title>
					Debts to pay
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item :to="{ name: 'creditospagados'}">
                <v-list-item-content>
                  <v-list-item-title>
                    Credits Paids
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item :to="{ name: 'inventarioactual'}">
                <v-list-item-content>
                  <v-list-item-title>
                    Current Inventory
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
			  <v-list-item :to="{ name: 'movimientoproductos'}">
                <v-list-item-content>
                  <v-list-item-title>
                    Items Movement
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
          </v-list-group>
        </template>
		<template v-if="esAdministrador || esSupervisor">
            <v-list-group color="secondary" >
              <v-list-item slot="activator">
                <v-list-item-action>
                <v-icon>mdi-storefront</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    Online Store <v-chip
									class="ma-2"
									color="red"
									text-color="white"
									>
									New
									</v-chip>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item :to="{ name: 'compuestos'}">
                <v-list-item-content>
                  <v-list-item-title>
                    Compound Items
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
			  <v-list-item :to="{ name: 'productosweb'}">
                <v-list-item-content>
                  <v-list-item-title>
                    Online Items
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
			  <v-list-item :to="{ name: 'feedback'}">
                <v-list-item-content>
                  <v-list-item-title>
                    FeedBacks
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
          </v-list-group>
        </template>
        <template v-if="esAdministrador || esSupervisor">
            <v-list-group color="secondary" >
              <v-list-item slot="activator">
                <v-list-item-action>
                <v-icon>mdi-tools</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    Tools
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
			  <v-list-item :to="{ name: 'odt'}">
                <v-list-item-content>
                  <v-list-item-title>
                    Work Order
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item :to="{ name: 'reparaciones'}">
                <v-list-item-content>
                  <v-list-item-title>
                    Repairs(En proceso)
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
			  <v-list-item :to="{ name: 'productosweb'}">
                <v-list-item-content>
                  <v-list-item-title>
                    Online Store items
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
			  <v-list-item v-if="esAdministrador" :to="{ name: 'licencias'}">
                <v-list-item-content>
                  <v-list-item-title>
                    License
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item :to="{ name: 'rol'}">
                <v-list-item-content>
                  <v-list-item-title>
                    Admin Roles
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item :to="{ name: 'tutoriales'}">
                <v-list-item-content>
                  <v-list-item-title>
                    Video Tutorials
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item :to="{ name: 'seriales'}">
                <v-list-item-content>
                  <v-list-item-title>
                     Serial Codes
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item :to="{ name: 'preaviso'}">
                <v-list-item-content>
                  <v-list-item-title>
                    PreAviso (curiers)
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item :to="{ name: 'panelcontrol'}">
                <v-list-item-content>
                  <v-list-item-title>
                    Control Panel
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
          </v-list-group>
        </template>
      </v-list>
	<template v-slot:append>
        <div class="pa-3">
          <v-btn color="secondary" @click="salir" block>Log Out</v-btn>
        </div>
      </template>
    </v-navigation-drawer>
    <v-main>
    <v-system-bar 
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      height="60"
      color="primary oculto-impresion"
      dark 
      v-if="logueado"
     >
      <v-app-bar-nav-icon v-if="logueado" @click.stop="drawer = !drawer" />
      <v-toolbar-title
        style="width: 300px"
        class="ml-0 pl-4">
        <v-card-actions>
        <v-img max-height="50" max-width="50" :src="require('../assets/Logo Claro.png')"></v-img>
        <div class="cliente">
        <v-list-item-title style="font-weight:bold;">{{userName}}</v-list-item-title>
        <v-list-item-subtitle>{{Localidad}}</v-list-item-subtitle> </div>
        </v-card-actions>
      </v-toolbar-title>
	  <v-card-subtitle> V2.9.2</v-card-subtitle>
	  <v-card-subtitle class="hidden-sm-and-down">Powered by<v-icon x-large>mdi-aws</v-icon> </v-card-subtitle>
      <v-spacer />

        <template>
          <div class="text-center">
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-width="200"
            >
				<template v-slot:activator="{ on, attrs }">
					<v-btn v-if="logueado" v-on="on" v-bind="attrs" text rounded > <v-icon  >mdi-account</v-icon></v-btn>
				</template>

              <v-card>
                <v-list>
                  <v-list-item>
                    <v-list-item-avatar>
                      <img src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light">
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title style="font-weight:bold;">{{userName}}</v-list-item-title>
                      <v-list-item-subtitle>{{Localidad}}</v-list-item-subtitle>
                    </v-list-item-content>
					<v-card-subtitle> V3.0 </v-card-subtitle>

                    <v-list-item-action>
                      <v-btn
                        :class="fav ? 'red--text' : ''"
                        icon
                        @click="fav = !fav"
                      >
                        <v-icon>mdi-heart</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>

                <v-divider></v-divider>

                <v-list>
                  <v-list-item>
                     <v-list-item-title>Plan: {{Plan}}</v-list-item-title>
                  </v-list-item>
                  <v-card-subtitle>
                    <div> 
                     Documentos Comprados: {{LimiteFacturas}} <br>
                     Documentos Efectuados: {{FacturasRealizadas}} <br>
                     Saldo de Documentos: {{SaldoFacturas}} </div>
                  </v-card-subtitle>
                    </v-list>

                <v-card-actions>
					<v-select prepend-icon="mdi-flag" label="Lenguaje" v-model="Pais" @change="idioma()" :items="Paises"> </v-select>
			
                   	<v-spacer></v-spacer>

					<v-btn text @click="menu = false">Cerrar</v-btn>
					<v-btn color="primary" text @click="salir" >Log out</v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </div>
        </template>
      <v-btn class="hidden-sm-and-down" text rounded to="/"> <v-icon  >mdi-home</v-icon></v-btn>
     <v-btn v-if="!logueado" color="secondary" rounded to="/login"> <v-icon >mdi-account-circle</v-icon></v-btn>
      
    </v-system-bar>
      <router-view></router-view>
    </v-main>
    <v-btn 
		v-if="!logueado"
      bottom
      color="success oculto-impresion"
      dark
      fab
      fixed
      right
      link href="https://chat.whatsapp.com/DGSSGdXIZd98DpWH5QxiVr" 
     > 
      <v-icon>mdi-whatsapp</v-icon>
    </v-btn >
    <v-footer
		v-if="!logueado"
        dark
        padless
        class="text-center oculto-impresion"
      >
      
          <v-card-text class="white--text pt-0 mt-5">
           Soporte técnico: 4070-1889 
          </v-card-text>

          <v-divider></v-divider>

          <v-card-text class="white--text" link href="https://www.noah.cr"  >
            {{ new Date().getFullYear() }} — <strong>Creado por Sistemas Informáticos Noah SRL Costa Rica</strong>
          </v-card-text>
       
		</v-footer>
		<v-dialog v-model="dialog" persistent max-width="600px">
			<v-card>
			<v-card-title class="headline">Actualización disponible</v-card-title>
			<v-card-text>Hay una nueva versión de la aplicación disponible. ¿Quieres actualizar ahora?</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="green darken-1" text @click="confirmUpdate">Aceptar</v-btn>
				<v-btn color="red darken-1" text @click="dialog = false">Cancelar</v-btn>
			</v-card-actions>
			</v-card>
		</v-dialog>
	</v-app>
    
</template>

<script>
       
  export default {
	filters: {
		formatNumber(value) {
			const formatter = new Intl.NumberFormat('en-US', {
			minimumFractionDigits: 0, // Mínimo número de dígitos fraccionarios
			maximumFractionDigits: 2, // Máximo número de dígitos fraccionarios
			});
			return formatter.format(value);
		}
	},
    name: 'App',
    
    props: {
      source: String,
    },
    data: () => ({
		dialog: false,
		drawer: null,
		diasCredito: null,
		LimiteFacturas: '',
		FacturasRealizadas: '',
		Paises: ["ESP","ING"],
		localidad:'',
		Pais: '',
		menu: false,
		timeUpdateInterval:null,
		SaldoFacturas: '',
		fav: true,
		Plan: '',
		diasCredito: '',
		icons: [
			'fab fa-facebook',
			'fab fa-twitter',
			'fab fa-google-plus',
			'fab fa-linkedin',
			'fab fa-instagram',
		],
		items: [
			{icon: 'mdi-speedometer', title:'Dashboard', route:'/', permiso:'esAdministrador'},
			{icon: 'mdi-account-group-outline', title:'Usuarios', route:'/usuariocliente',permiso:'esSupervisor'},
			{icon: 'mdi-printer-pos', title:'POS', route:'/pos'},
			{icon: 'mdi-ipod', title:'Productos', route:'/dashboard',permiso:'esAdministrador'},
			{icon: 'mdi-account-supervisor-circle', title:'Clientes', route:'/clientes'},
			{icon: 'mdi-shopping', title:'Egresos', route:'/compras'},
			
		],
		items2: [
			{icon: 'mdi-speedometer', title:'Dashboard', route:'/', permiso:'esAdministrador'},
			{icon: 'mdi-calendar', title:'Calendar', route:'/calendar',permiso:'esAdministrador || esSupervisor'},
			{icon: 'mdi-account-group-outline', title:'Users', route:'/usuariocliente',permiso:'esSupervisor'},
			{icon: 'mdi-printer-pos', title:'POS', route:'/pos'},
			{icon: 'mdi-ipod', title:'Items', route:'/dashboard',permiso:'esAdministrador'},
			{icon: 'mdi-account-supervisor-circle', title:'Customers', route:'/clientes'},
			{icon: 'mdi-shopping', title:'Purchases', route:'/compras'},
			
		],	
    }),
	mounted() {
		// Una vez que el componente está montado, oculta el preloader.
		const preloader = document.getElementById('preloader');
		if (preloader) {
			preloader.style.display = 'none';
		}
		window.addEventListener('online', this.handleOnline);
		window.addEventListener('offline', this.handleOffline);
	},
	beforeDestroy() {
		// Remover event listeners antes de que el componente se destruya
		window.removeEventListener('online', this.handleOnline);
		window.removeEventListener('offline', this.handleOffline);
	},
    computed: {
		logueado() {
      // Accede al estado de Vuex para determinar si el usuario está logueado.
      return !!this.$store.state.usuario; // Devuelve true si `usuario` está definido, de lo contrario false.
    },
		userName (){
				return this.$store.state.usuario.nombre
		},
		esTaller(){
			return this.$store.state.usuario && this.$store.state.usuario.rol =='Gerente Taller';
		},
		Localidad (){
			return this.$store.state.usuario.localidad
		},
		esAdministrador(){
			return this.$store.state.usuario && this.$store.state.usuario.rol =='Administrador';
		},
		esContador(){
			return this.$store.state.usuario && this.$store.state.usuario.rol =='Contador';
		},
		esSupervisor(){
       	 	return this.$store.state.usuario && this.$store.state.usuario.rol =='Supervisor';
		},
		esReceptor(){
       	 	return this.$store.state.usuario && this.$store.state.usuario.rol =='Recepcion';
		},
	  	esBodega(){
			return this.$store.state.usuario && this.$store.state.usuario.rol =='Bodegas';
		},
		esVendedor(){
			return this.$store.state.usuario && this.$store.state.usuario.rol =='Vendedor';
		},
		esCliente(){
			return this.$store.state.usuario && this.$store.state.usuario.rol =='Cliente';
		},
		esRecepcion(){
			return this.$store.state.usuario && this.$store.state.usuario.rol =='Recepcion';
		},
		esCrifasa(){
			return this.$store.state.usuario && this.$store.state.usuario.rol =='Crifasa';
		},
		esBoss(){
			return this.$store.state.usuario && this.$store.state.usuario.rol =='King Boss';
		},
		esMarketing(){
			return this.$store.state.usuario && this.$store.state.usuario.rol =='King Marketing';
		},
		esFacturador(){
			return this.$store.state.usuario && this.$store.state.usuario.rol =='King Facturador';
		},
		KingSupervisor(){
			return this.$store.state.usuario && this.$store.state.usuario.rol =='King Supervisor';
		},
		esCenter(){
			return this.$store.state.usuario && this.$store.state.usuario.nombre =='King Vape Center';
		},
		
  	},
    created() {
		this.$store.dispatch('autoLogin').then(() => {
			// Puedes realizar acciones adicionales aquí si es necesario después de que el usuario esté autenticado
		});
		this.validarIdioma();
    },
    methods: {
		handleOnline() {
			this.$snackbar.show('Conexión a Internet restaurada', {
				color: 'error'
			});
		},
		handleOffline() {
			this.$snackbar.show('Conexión a Internet perdida', {
				color: 'error'
			});
		},
		salir(){
			this.$store.dispatch("salir");
		},
		validarIdioma(){
			if(localStorage.getItem("idioma") == null) {
				this.Pais = "ESP";
				return;
			}

			this.Pais = localStorage.getItem("idioma");
		},
		idioma(){
			localStorage.setItem("idioma",this.Pais)
			location.reload();
		},
		confirmUpdate() {
			// El usuario ha aceptado la actualización
			const currentAppVersion = '2.6.7'; // Repite la versión actual aquí para asegurar consistencia
			// Actualiza la versión almacenada en localStorage con la versión actual
			localStorage.setItem('appVersion', currentAppVersion);
			// Forza una recarga de la página para aplicar la actualización
			window.location.reload(true);
		},
		cancelUpdate() {
			// El usuario ha cancelado o cerrado el diálogo de actualización
			this.dialog = false; // Cierra el diálogo
			// Aquí podrías implementar cualquier otra lógica necesaria cuando el usuario decide no actualizar
		},
    }

  }

</script>
